var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"productCardRef",staticClass:"product-card gift-expanded-container",class:[_vm.promoClass],attrs:{"outlined":"","rounded":"md","to":{ name: 'Product', params: { slug: _vm.product.slug } }}},[_c('div',{staticClass:"product"},[_c('div',{staticClass:"promo-wrapper"},[_vm._l((_vm.promoProductClasses),function(pClass){return _c('ProductClass',{key:pClass.productClassid,attrs:{"pClass":pClass}})}),(_vm.product.warehousePromo)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.product.warehousePromo}}):_vm._e(),_vm._l((_vm.product.userGiftCertificates),function(userGiftCertificate){return _c('ProductGiftCertificate',{key:userGiftCertificate.giftCertificate.giftCertificateId,attrs:{"giftCertificate":userGiftCertificate.giftCertificate,"isCard":true},on:{"add":_vm.giftAdded}})})],2),_c('div',{staticClass:"d-flex justify-end align-center w-100 top"},[_c('div',{staticClass:"ml-1 d-flex align-center"},[_c('ProductAcqLimit',{attrs:{"product":_vm.product}}),_c('v-tooltip',{key:_vm.heartKey,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(_vm.highlight)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$heartfull")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("$heart")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("product.addToList")))])])],1)]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column body"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.product.mediaURL,"onerror":"this.onerror=null;this.src='/no-icon.png'","width":_vm.$vuetify.breakpoint.xs ? 110 : 150,"height":_vm.$vuetify.breakpoint.xs ? 110 : 150,"alt":'Immagine' + _vm.product.name,"title":_vm.product.name + ' (' + _vm.product.codInt + '-' + _vm.product.codVar + ')'}}),_c('div',{staticClass:"description default--text d-flex flex-column justify-left w-100 mt-2 ml-1 ml-sm-0 mb-2"},[_c('span',{staticClass:"name font-weight-bold"},[_vm._v(" "+_vm._s(_vm.product.name)+" "+_vm._s(_vm.product.description)+" ")]),_c('span',{staticClass:"text_caption short_descr"},[_vm._v(" "+_vm._s(_vm.product.shortDescr)+" ")]),_c('span',{staticClass:"descr"},[(
                _vm.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  _vm.product.productInfos.PACKAGE_DESCR
              )?_c('span',{staticClass:"text-caption rounded-sm default--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.product.productInfos.PACKAGE_DESCR)+" ")]):_vm._e()]),_c('v-spacer'),(
              _vm.product.warehousePromo && _vm.product.warehousePromo.view.bubble
            )?_c('span',{staticClass:"promo-badge",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.bubble)}}):_c('div',{staticClass:"d-flex"},[_vm._l((_vm.productClasses),function(pClass){return _c('ProductClass',{key:pClass.productClassid,attrs:{"pClass":pClass}})}),(_vm.product.newProduct == true)?_c('span',{staticClass:"new-badge"},[_vm._v(" Novità ")]):_vm._e()],2)],1)]),_c('v-divider',{staticClass:"px-1"}),(_vm.selectedOptions.customWeight)?_c('div',{staticClass:"cart-item-info",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openOptionsModal(_vm.product.selectOptions)}}},[_c('em',[_vm._v("Grammatura: "+_vm._s(_vm.selectedOptions.customWeight)+"gr")])]):_vm._e(),(_vm.product.priceDisplay && _vm.product.available > 0)?_c('div',{staticClass:"actions my-1"},[_c('ProductPrice',{attrs:{"product":_vm.product}}),_c('ProductQty',{attrs:{"selectedOptions":_vm.selectedOptions,"product":_vm.product,"item":_vm.item}})],1):_c('div',{staticClass:"actions justify-center text-center text-caption error--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("product.notAvailable"))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }