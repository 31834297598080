<template>
  <div
    class="d-flex flex-column justify-space-around"
    :class="boxClass"
    @click.prevent.stop="handleClick('.gift-expanded-container')"
    :style="{ width: boxWidth, height: boxHeight }"
    v-ripple
  >
    <span v-if="!isOpen" class="promo-tag">
      SOLO PER <strong>TE</strong><br />
      <span class="small-text" v-if="mode != 'badge'">
        scopri<v-icon dense color="white" size="11px">
          $arrowForward
        </v-icon>
      </span>
    </span>
    <span v-if="isOpen" class="icon-circle">
      <v-icon>
        {{ icon }}
      </v-icon>
    </span>
    <span v-if="isOpen && name" class="promo-body-black">
      {{ name }}
    </span>
    <span class="content" v-show="isOpen" v-html="shortDescription"> </span>

    <v-btn
      v-if="giftCertificate.giftCode && isOpen && userGiftCertificateId != null"
      outlined
      depressed
      color="primary"
      min-width="80"
      @click.stop.prevent="remove(userGiftCertificateId)"
      >{{ $t("checkout.giftCodeRemove") }}
    </v-btn>
    <v-btn
      v-else-if="giftCertificate.giftCode && isOpen"
      class="btn-add"
      @click="add"
      elevation="0"
    >
      {{ $t("checkout.giftCodeUseNow") }}
    </v-btn>
    <v-btn
      v-if="link && isOpen"
      class="btn-more"
      outlined
      :to="link"
      @click="closeWindow"
    >
      {{ btnLabel }}
    </v-btn>
  </div>
</template>
<script>
import get from "lodash/get";
import clickHandler from "~/mixins/clickHandler";
import giftCertificate from "@/components/gift/giftCertificate";

import Vue from "vue";
import GiftCertificateWindow from "./GiftCertificateWindow.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalGiftCard from "@/components/gift/ProposalGiftCard.vue";

export default {
  name: "ProductGiftCertificate",
  data() {
    return { isOpen: false, boxWidth: "80px", boxHeight: "30px" };
  },
  mixins: [clickHandler, giftCertificate],
  props: { isCard: { type: Boolean, default: false } },
  computed: {
    mode() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.MODE",
        "badge"
      );
    },
    boxClass() {
      return [
        "certificate-box",
        this.isOpen ? "is-open" : "",
        this.cssClass
      ].join(" ");
    }
  },
  methods: {
    closeWindow() {
      this.isOpen = false;
    },
    handleClick(selector) {
      if (this.mode == "link") {
        this.$router.push({
          name: "Category",
          params: { pathMatch: this.giftCertificate.categoryProposal.slug }
        });
      } else if (this.mode == "card") {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          if (selector) {
            let container = this.$el.closest(selector);
            this.boxWidth = this.isCard ? container.clientWidth + "px" : "100%";
            this.boxHeight = container.clientHeight + "px";
          } else {
            this.boxWidth = "100%";
            this.boxHeight = "100%";
          }
        } else {
          this.boxWidth = "80px";
          this.boxHeight = "30px";
        }
      } else if (this.mode == "products") {
        //open popup with products
        global.EventBus.$emit("close-gift-cert-window");
        new (Vue.extend(GiftCertificateWindow))({
          parent: this,
          propsData: {
            isOpen: true,
            giftCertificate: this.giftCertificate
          },
          components: {
            ProductListSlider,
            ProposalGiftCard
          },
          methods: {
            giftAdded: function() {
              this.$emit("add");
            }
          }
        }).$mount(this.$el.querySelector("#app"));
      }
    }
  }
};
</script>
