<template>
  <v-card
    outlined
    rounded="md"
    class="product-card gift-expanded-container"
    :class="[promoClass]"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productCardRef"
  >
    <div class="product">
      <div class="promo-wrapper">
        <ProductClass
          :pClass="pClass"
          v-for="pClass in promoProductClasses"
          :key="pClass.productClassid"
        />
        <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <ProductGiftCertificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          :isCard="true"
          @add="giftAdded"
        />
      </div>
      <div class="d-flex justify-end align-center w-100 top">
        <div class="ml-1 d-flex align-center">
          <ProductAcqLimit :product="product" />
          <!-- <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                v-if="product.vendor.url"
                :src="product.vendor.url"
                height="26px"
                contain
              />
            </template>
            <span>{{ product.vendor.name }}</span>
          </v-tooltip> -->
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
              >
                <v-icon color="primary" v-if="highlight">$heartfull</v-icon>
                <v-icon v-else color="grey">$heart</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :width="$vuetify.breakpoint.xs ? 110 : 150"
            :height="$vuetify.breakpoint.xs ? 110 : 150"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-2 ml-1 ml-sm-0 mb-2"
          >
            <span class="name font-weight-bold">
              {{ product.name }} {{ product.description }}
            </span>
            <span class="text_caption short_descr">
              {{ product.shortDescr }}
            </span>
            <span class="descr">
              <span
                class="text-caption rounded-sm default--text font-weight-bold"
                v-if="
                  product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    product.productInfos.PACKAGE_DESCR
                "
              >
                {{ product.productInfos.PACKAGE_DESCR }}
              </span>
            </span>
            <v-spacer />
            <span
              v-if="
                product.warehousePromo && product.warehousePromo.view.bubble
              "
              class="promo-badge"
              v-html="product.warehousePromo.view.bubble"
            >
            </span>
            <div v-else class="d-flex">
              <ProductClass
                :pClass="pClass"
                v-for="pClass in productClasses"
                :key="pClass.productClassid"
              />
              <span v-if="product.newProduct == true" class="new-badge">
                Novità
              </span>
            </div>
          </div>
        </div>
        <v-divider class="px-1" />
        <div
          v-if="selectedOptions.customWeight"
          class="cart-item-info"
          @click.stop.prevent="openOptionsModal(product.selectOptions)"
        >
          <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
        </div>
        <div
          v-if="product.priceDisplay && product.available > 0"
          class="actions my-1"
        >
          <ProductPrice :product="product" />
          <ProductQty
            :selectedOptions="selectedOptions"
            :product="product"
            :item="item"
          />
        </div>
        <div
          v-else
          class="actions justify-center text-center text-caption error--text font-weight-bold"
        >
          {{ $t("product.notAvailable") }}
        </div>
      </div>
    </div>
  </v-card>
</template>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .product-card {
    height: 370px;
  }
}
.promo-wrapper {
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.follie {
  .promo-wrapper {
    top: 0px;
    left: 0px;
  }
  ::v-deep img {
    border-top-left-radius: 5px;
  }
}
.cart-item-info {
  margin-bottom: -21px;
}
.actions {
  min-height: 58px;
}

.top {
  height: 26px;
}

.has-promo {
  border-color: var(--v-promo-base) !important;
}
// .product .description .name {
//   padding-bottom: 27px;
// }
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description

import ProductClass from "./ProductClass.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPromo from "./ProductPromo.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductGiftCertificate from "@/components/gift/ProductGiftCertificate.vue";
import ProductQty from "./ProductQty.vue";
// import ProductInfo from "./ProductInfo.vue";
// import LeadTime from "./ProductLeadTime.vue";
// import LockCutoff from "./ProductLockCutoff.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
// import DayLock from "./ProductDayLock.vue";
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";

export default {
  name: "ProductCard",
  props: { product: { type: Object, required: true } },
  components: {
    // ProductInfo,
    ProductClass,
    ProductAcqLimit,
    ProductPromo,
    ProductPrice,
    ProductGiftCertificate,
    ProductQty
    // LeadTime,
    // LockCutoff,
    // AxBCutoff,
    // DayLock
  },
  mixins: [productMixin],
  data() {
    return {
      selectedOptions: {}
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    item() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    }
  }
};
</script>
