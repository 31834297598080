<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    content-class="gift-certificate-window"
  >
    <ProductListSlider
      :proposal="giftCertificate.categoryProposal"
      mode="gift"
      cols="auto"
      sm="auto"
      md="auto"
      lg="auto"
      xl="auto"
    >
      <template #firstCard>
        <ProposalGiftCard
          :giftCertificate="giftCertificate"
          @add="giftAdded"
          @submit="isOpen = false"
        />
      </template>
    </ProductListSlider>
  </v-dialog>
</template>
<style lang="scss">
.gift-certificate-window {
  align-self: flex-end;
  width: 100%;
  margin: 0;
  padding: 10px;
  .description {
    width: 200px;
  }
}
</style>
<script>
import ProposalGiftCard from "@/components/gift/ProposalGiftCard.vue";

export default {
  components: {
    ProductListSlider: () =>
      import("@/components/product/ProductListSlider.vue"),
    ProposalGiftCard
  },
  name: "GiftCertificateWindow",
  data() {
    return {
      isOpen: true
      // giftCertificate: {}
    };
  },
  props: { giftCertificate: { type: Object, required: true } },
  methods: {
    giftAdded() {
      this.isOpen = false;
    }
  },
  mounted() {
    global.EventBus.$once("close-gift-cert-window", () => {
      this.$destroy();
    });
  }
};
</script>
