<template>
  <v-tooltip top v-if="pClass.iconSource">
    <template v-slot:activator="{ on, attrs }">
      <div class="mr-1 product-class" v-bind="attrs" v-on="on">
        <img
          aria-hidden
          :src="pClass.iconSource"
          :alt="pClass.name"
          :title="pClass.name"
          class="product-class-icon"
        />
      </div>
    </template>
    <span>{{ pClass.name }}</span>
  </v-tooltip>
</template>
<style scoped lang="scss">
div {
  width: fit-content;
}
.product-class-icon {
  min-width: 22px;
  height: auto;
}
</style>
<script>
export default {
  name: "ProductClass",
  props: {
    pClass: { type: Object },
    height: { type: Number, default: 22 },
    width: { type: Number, default: 22 }
  }
};
</script>
