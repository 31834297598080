<template>
  <v-card
    class="certificate-card d-flex flex-column justify-space-around align-center"
    :class="cssClass"
    @click.prevent.stop="clicked"
    outlined
  >
    <span class="icon-circle">
      <v-icon x-large>{{ icon }}</v-icon>
    </span>
    <span class="promo-body">
      {{ giftCertificate.categoryProposal.name }}
    </span>
    <span
      class="content px-1 text-center text-sm-left"
      v-html="shortDescription"
    >
    </span>
    <v-btn
      v-if="giftCertificate.giftCode && userGiftCertificateId != null"
      outlined
      depressed
      color="primary"
      min-width="80"
      @click.stop.prevent="remove(userGiftCertificateId)"
      >{{ $t("checkout.giftCodeRemove") }}
    </v-btn>
    <v-btn
      v-else-if="giftCertificate.giftCode"
      :color="cssClass"
      @click="addGift"
    >
      {{ $t("checkout.giftCodeUseNow") }}
    </v-btn>
    <v-btn
      v-if="link"
      outlined
      :color="cssClass"
      :to="link"
      @click="closeWindow"
    >
      {{ btnLabel }}
    </v-btn>
  </v-card>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "ProposalGiftCard",
  mixins: [clickHandler, giftCertificate],
  methods: {
    closeWindow() {
      this.$emit("submit");
    },
    addGift() {
      this.add();
      this.closeWindow();
    }
  }
};
</script>
